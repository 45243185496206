import { CONTAINER_FILLING_CONTAINTERTYPE } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Container Type", "search-by": "Code,Description,AutoNumbering", "ph-search-by": "Search by Container Type, Description, Numbering", "has-delete": true, "has-active": false, "has-filter-inactive": false } }, [_c("text-field", { key: "Code", attrs: { "data-index": "code", "title": "Container Type", "width": 170, "sorter": true } }), _c("text-field", { key: "Description", attrs: { "data-index": "description", "title": "Description" } }), _c("text-field", { key: "TareWeight", attrs: { "data-index": "tareWeight", "title": "Tare Weight", "width": 150, "sorter": true } }), _c("text-field", { key: "AutoNumbering", attrs: { "data-index": "autoNumbering", "title": "Numbering", "width": 150, "sorter": true } }), _c("text-field", { key: "WeighsPerContainer", attrs: { "data-index": "weighsPerContainer", "title": "Weighs per Container", "width": 150, "sorter": true } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "ListContainerType"
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListContainerType = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-container-type" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "page": _vm.page, "name": "cf.container-types", "api-url": _vm.apiUrl, "create-route": "/container-filling/container-type/create", "edit-route": "/container-filling/container-type/:id" } }, [_c("list-container-type")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListContainerType
  },
  data() {
    return {
      page: CONTAINER_FILLING_CONTAINTERTYPE,
      ListContainerType,
      apiUrl,
      itemsMenu: [
        {
          key: "masterfiles",
          title: "Master Files",
          path: ""
        },
        {
          key: "container-type",
          title: "Container Types",
          path: "/container-filling/container-type"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
